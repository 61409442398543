import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';
import { AnswerInputReport } from './AnswerInputReport';

/*
  вопрос в режиме отчета
*/

const useStyles = makeStyles((theme) => ({
    repQuestion: {
        margin: theme.spacing(1)
    },
    anwInput: {
        paddingLeft: '0px',
    },
    contArea: {
        overflow: 'hidden',
        '& p':{
            marginTop: '0px'
        }
    }
}));

function renderStandartContent(content, styles) {

    const rawText = content.qText
        .map((itm) => itm.type === 'text' ? itm.value : '')
        .join(' ');

    const qText = ReactHtmlParser(rawText);

    const answers = content.answersReport.map(
        (val, idx) =>
            <tr>
                <td style={{whiteSpace: 'nowrap'}} valign='top' className={styles.anwInput}>
                    <AnswerInputReport
                        type={content.qstType}
                        userAnswer={val.userAnswer}
                        answNumber={idx}
                    />
                </td>
                <td valign='top' className={styles.contArea}>
                    {ReactHtmlParser(val.text)}
                </td>
            </tr>
    );
    return (
        <React.Fragment>
            <div className={styles.contArea}>{qText}</div>
            <table border="0">
                {answers}
            </table>
        </React.Fragment>
    );
}

function renderFreeContent(content) {

    const rawText = content.qText
        .map((itm) => itm.type === 'text' ? itm.value : '<answinput ></answinput>').
        join('');

    let idx = 0;

    const cont = ReactHtmlParser(rawText, {
        transform: (node) => {
            if (node.type === 'tag' && node.name === 'answinput') {
                const ss = idx++;

                return `[${content.answersReport[ss].userAnswer}]`;
            } else {
                return undefined;
            }
        }
    });



    return (
        <div>
            {cont}
        </div>
    );
}

export function QuestionReport(props) {

    const classes = useStyles();

    return (
        <div className={classes.repQuestion}>
            <Typography variant="subtitle2" component="h3" >Вопрос №{props.content.totalNumber}</Typography>
            {props.content.freeFormat ? renderFreeContent(props.content) : renderStandartContent(props.content, classes)}
        </div>
    )
}