import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, CardActions, Button, Divider, Typography, Table, TableRow, TableCell } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

/*
    итоги выполнения теста
*/

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    minHeight: 250,
    width: 400
  },
  actionsArea: {
    padding: '8px 8px 8px 8px',
    justifyContent: 'center'
  }
})

function outMark(mark) {
  if (mark != '') {
    return (
      <TableRow>
        <TableCell>Оценка:</TableCell>
        <TableCell>{mark}</TableCell>
      </TableRow>
    )
  } else {
    return (null)
  }
}

export function TestResults(props) {
  const classes = useStyles();
  let spDt = new Date(props.content.timeSpent);
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
    >
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              W
            </Avatar>
          }
          titleTypographyProps={{ variant: 'h6' }}
          title="Тест завершен"
        />
        <CardContent>
          <Table size="small">
            <TableRow>
              <TableCell>Пройдено секций:</TableCell>
              <TableCell>{props.content.sectAnswered} из {props.content.sectCount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Пройдено вопросов:</TableCell>
              <TableCell>{props.content.questAnswered} из {props.content.questCount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Набрано баллов:</TableCell>
              <TableCell>{props.content.curRating} из {props.content.maxRating}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Затрачено времени:</TableCell>
              <TableCell>{spDt.getUTCHours() + ':' + spDt.getUTCMinutes() + ':' + spDt.getUTCSeconds()}</TableCell>
            </TableRow>
            {outMark(props.content.mark)}
          </Table>
        </CardContent>
        <CardActions className={classes.actionsArea}>
          <Button size="large" color="primary" disabled={!props.onShowReport} onClick={props.onShowReport}>Отчет</Button>
          <Button size="large" color="primary" onClick={props.onRepeatTest}>Повторить</Button>
          <Button size="large" color="primary" onClick={props.onSelectOtherTest}>Закрыть</Button>
        </CardActions>
      </Card>
    </Box>
  );
}