import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { SummaryTimer } from './SuumaryTimer';

/* 
  строка итогов
*/

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

export function Summary(props) {
  
  const classes = useStyles();

  const outTimeInfo = () => {
    if (props.content.timeMax > 0) {
      return (
        <React.Fragment>
          <span> | </span>
          <span><SummaryTimer startTime={props.content.timeMax -  props.content.timeSpent} onTimeOver={props.onTimeOver}/></span>
        </React.Fragment>
      );
    }
  }

  return (
    <Paper className={classes.paper}>
      <span >Пройдено секций: {props.content.sectAnswered} из {props.content.sectCount}</span>
      <span> | </span>
      <span>Пройдено вопросов: {props.content.questAnswered} из {props.content.questCount}</span>
      <span> | </span>
      <span>Набрано баллов: {props.content.curRating} из {props.content.maxRating}</span>
      {outTimeInfo()}
    </Paper>
  );
}
