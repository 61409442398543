import React from 'react';
import Box from '@material-ui/core/Box';
import { Card, CardContent, CardHeader, CardActions, Button, Divider } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { PinDropSharp } from '@material-ui/icons';

/*
  информация о тесте перед началом тестирования
*/

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    width: 400,
    margin: 2
  }
})

export function TestStartInfo(props) {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
    >
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              W
            </Avatar>
          }
          titleTypographyProps={{ variant: 'h6' }}
          title = {props.testInfo.title}
          subheader={`К-во попыток: ${props.testInfo.attemptCnt == 0?'неогр.':props.testInfo.attemptCnt}. Время: ${props.testInfo.time == 0?'неогр.':props.testInfo.time + 'мин'} `}
        />
        <CardContent>
          <Divider light />
        </CardContent>
        <CardActions>
          <Button size="large" color="primary" fullWidth onClick={props.onStartTest}>Начать тест</Button>
        </CardActions>
      </Card>

    </Box>

  );
}
