import React, { Component } from 'react';
import {SectionReport} from './SectionReport';

/*
    тело отчета
*/

export function ReportBody(props) {
    return (
        props.content.map((itm) => <SectionReport content={itm} />)
    )
}