import React, { Component } from 'react';
import { LoginForm } from './LoginForm';
import { RegistrationForm } from './RegistrationForm';
import { AuthMsg } from './AuthMsg';
import { postJson, putJson } from './comunications';
import { ErrorMessage } from './ErrorMessage';

/* 
  компонент, отвечающий за авторизацию пользователя в системе. Выводит форму авторизации, или регистрации, отправляет
  запрос на авторизацию в бэкэнд
*/

const ShowMode = Object.freeze({
    LOGIN: 0,
    REGISTRATION: 1,
    REGSUCSESSMSG: 2,
    PASSWSENDMSG: 3
});

export class AuthorizationArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            curMode: ShowMode.LOGIN,
            authError: null,
            lastLogin: null,
            lastPassword: null,
            regEmail: null,
            regFirstName: null,
            regLastName: null,
            regError: null,
            serverError: null
        }

        this.login = this.login.bind(this);
        this.registration = this.registration.bind(this);
        this.restorePassword = this.restorePassword.bind(this);
    }

    login(data) {
        let st = {
            lastLogin: data.login,
            lastPassword: data.password,
            authError: null,
            serverError: null
        }

        postJson('/api/frontUsers/login', { 'email': data.login, 'password': data.password }, (err, json) => {
            if (err) {
                if (err.userError) {
                    st.authError = 'пользователь с такой почтой или паролем не найден';
                } else {
                    st.serverError = err.message;
                }
                this.setState(st);
            } else {
                this.props.onAuthorization(json.token);
            }
        })
    }

    restorePassword(data) {
        let st = {
            lastLogin: data.email,
            authError: null,
            serverError: null
        }

        postJson('/api/frontUsers/password', { 'email': data.email }, (err, json) => {
            if (err) {
                if (err.userError) {
                    st.authError = err.message;
                } else {
                    st.serverError = err.message;
                }
                this.setState(st);
            } else {
                st.curMode = ShowMode.PASSWSENDMSG;
                this.setState(st)
            }
        })
    }

    registration(data) {
        let st = {
            regEmail: data.email,
            regFirstName: data.firstName,
            regLastName: data.lastName,
            regError: null,
            serverError: null
        }

        putJson('/api/frontUsers', data, (err, json) => {
            if (err) {
                if (err.userError) {
                    st.regError = err.message
                } else {
                    st.serverError = err.message
                }
                this.setState(st);
            } else {
                st.lastLogin = data.email;
                st.curMode = ShowMode.REGSUCSESSMSG
                this.setState(st)
            }
        })
    }

    render() {
        const { classes } = this.props;

        switch (this.state.curMode) {
            case ShowMode.LOGIN:
            case ShowMode.PASSWSENDMSG:
                return (
                    <div>
                        <ErrorMessage message={this.state.serverError} onClose={() => this.setState({ serverError: null })} />
                        <LoginForm
                            login={this.state.lastLogin}
                            password={this.state.lastPassword}
                            error={this.state.authError}
                            onLogin={this.login}
                            onNeedRegistration={() => { this.setState({ curMode: ShowMode.REGISTRATION }) }}
                            onRestorePassword={this.restorePassword}
                        />
                        <AuthMsg
                            active={this.state.curMode === ShowMode.PASSWSENDMSG}
                            title={"Восстановление пароля"}
                            message={'Пароль для входа был выслан на адрес электронной почты, указанный при регистрации.'}
                            onClose={() => this.setState({ curMode: ShowMode.LOGIN })}
                        />
                    </div>
                )
                break;
            case ShowMode.REGISTRATION:
            case ShowMode.REGSUCSESSMSG:
                return (
                    <div>
                        <ErrorMessage message={this.state.serverError} onClose={() => this.setState({ serverError: null })} />
                        <RegistrationForm
                            email={this.state.regEmail}
                            firstName={this.state.regFirstName}
                            lastName={this.state.regLastName}
                            error={this.state.regError}
                            onRegistration={this.registration}
                        />
                        <AuthMsg
                            active={this.state.curMode === ShowMode.REGSUCSESSMSG}
                            title={"Регистрация завершена"}
                            message={'Пароль для входа был выслан на адрес электронной почты, указанный при регистрации.'}
                            onClose={() => this.setState({ curMode: ShowMode.LOGIN })}
                        />
                    </div>

                )
                break;
        }

    }
}