import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReportSummary } from './ReportSummary';
import { ReportBody } from './ReportBody';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';

/*
    отчет о выполнении теста
*/

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
        minHeight: '99vh'
    },

    h1: {
        marginBottom: theme.spacing(2)
    },

    h2: {
        marginBottom: theme.spacing(1)
    },

    closeBtn: {

        marginRight: theme.spacing(2),
    },

    btnArea: {
        position: 'fixed',
        zIndex: 99999,
        maxWidth: '912px',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '16px',
        paddingRight: '16px',
        bottom: theme.spacing(2),
        textAlign: 'right'
    }
}));


export function TestReport(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.btnArea}>
                <Fab color="primary" aria-label="add" className={classes.closeBtn} onClick={props.onCloseReport}>
                    <CloseIcon />
                </Fab>
            </div>
            <Paper className={classes.paper}>


                <Typography variant="h6" component="h1" className={classes.h1}>Отчет о выполении теста</Typography>
                <Typography variant="subtitle2" component="h2" className={classes.h2}>1. Результаты тестирования</Typography>
                <ReportSummary
                    content={props.content.summary}
                />
                <Typography variant="subtitle2" component="h2" className={classes.h2}>2. Протокол тестирования</Typography>
                <ReportBody
                    content={props.content.sectionsReport}
                />

            </Paper>
        </React.Fragment>

    );
}