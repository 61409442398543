import React from 'react';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

/*
  поле для ввода ответа
*/

const useStyles = makeStyles({
  answInputEnter: {
    verticalAlign: "middle",
  }
})

export function AnswerInput(props) {
  const classes = useStyles();
  let ansInput;
  switch (props.type) {
    case 'single':
      ansInput = <Radio checked={props.curVal} onChange={(env) => props.onSetVal(Number(env.target.checked))} />;
      break;
    case 'check':
      ansInput = <Checkbox checked={props.curVal} onChange={(env) => props.onSetVal(Number(env.target.checked))} />;
      break;
    case 'number':
      ansInput = <TextField className={classes.answInputEnter} value={props.curVal} onChange={(env) => props.onSetVal(env.target.value)} />;
      break;
    case 'text':
      ansInput = <TextField className={classes.answInputEnter} id="standard-size-small" size="small" value={props.curVal} onChange={(env) => props.onSetVal(env.target.value)} />;
      break;
    case 'box':
      ansInput =
        <Select value={props.curVal} onChange={(env) => props.onSetVal(env.target.value)}>
          <MenuItem key={-1}></MenuItem>
          {props.variants.map((val, idx) => <MenuItem value={val} key={idx}>{val}</MenuItem>
          )}
        </Select>;
  }
  return (ansInput);
}
