import React, { Component } from 'react';
import './App.css';
import TestCodeInput from './TestCodeInput';
import { TestArea } from './TestArea';
import Container from '@material-ui/core/Container';
import { withStyles, } from '@material-ui/core';
import { AuthorizationArea } from './AuthorizationArea';
import { ErrorMessage } from './ErrorMessage';
import { getJson, postJson } from './comunications';


const styles = {
  "@global": {
    body: {
      backgroundImage: "url('/background.jpg')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      height: "100%",
      // запретить копировать текст
      webkitUserSelect: "none", /* для Safari */
      mozUserSelect: "none",    /* для Firefox */
      msUserSelect: "none",     /* для Internet Explorer */
      userSelect: "none"
    }
  }
}

class App extends Component {

  constructor(props) {
    super();

    this.state = {
      testCode: '',
      userError: null,
      serverError: null,
      testInfo: null,
      waitAuthorization: false
    }

    this.onSelectTestCode = this.onSelectTestCode.bind(this);
    this.onSelectOtherTest = this.onSelectOtherTest.bind(this);
    this.onRepeatTest = this.onRepeatTest.bind(this);
    this.onAuthorization = this.onAuthorization.bind(this);
  }

  loadTestInfo(testCode) {
    getJson(`/api/test?uid=${testCode}`, (err, data) => {
      if (err) {
        this.setState({
          testCode: testCode,
          serverError: !err.userError ? err.message : null,
          userError: err.userError && err.code != 401 ? err.message : null,
          waitAuthorization: err.code == 401
        })
      } else {
        this.setState(
          {
            testCode: testCode,
            serverError: null,
            userError: null,
            testInfo: data,
            waitAuthorization: false
          }
        )
      }
    })
  }

  onSelectTestCode(testCode) {
    this.redirectToTest(testCode);
  }

  onSelectOtherTest() {
    this.redirectToTest(null);
  }

  onRepeatTest() {
    this.redirectToTest(this.state.testCode)
  }

  onAuthorization(token) {
    sessionStorage.setItem('token', token);
    this.loadTestInfo(this.state.testCode);
  }

  redirectToTest(testCode) {
    let url = new URL(window.location.toString());
    if (testCode) {
      url.searchParams.set('testCode', testCode)
    } else {
      url.searchParams.delete('testCode');
    }
    url.hash = '';
    window.location.replace(url)
  }

  renderBody() {
    if (!this.state.testInfo) {
      if (this.state.waitAuthorization) {
        return (<AuthorizationArea testInfo={this.state.testInfo} onAuthorization={this.onAuthorization} />)
      } else {
        return (
          <TestCodeInput
            initTestCode={this.state.testCode}
            onSelectCode={this.onSelectTestCode}
            error={this.state.userError}
            key={this.state.testCode}
          />
        )
      }
    } else {
      return (
        <TestArea
          testInfo={this.state.testInfo}
          onSelectOtherTest={this.onSelectOtherTest}
          onRepeatTest={this.onRepeatTest}
        />
      )
    }
  }

  render() {
    return (
      <Container maxWidth="md">
        <ErrorMessage message={this.state.serverError} onClose={() => this.setState({ serverError: null })} />
        {this.renderBody()}
      </Container>
    )
  }

  componentDidMount() {
    if (this.props.testCode) {
      this.loadTestInfo(this.props.testCode)
    }
  }
}

export default withStyles(styles)(App);
