import React, { Component } from 'react';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

/*
  поле для ввода ответа в режиме отчета
*/

export function AnswerInputReport(props) {
    let ansInput;
    const pref = 'abcdefghijklmnopqrstuvwzyz';

    if (['single', 'check'].includes(props.type)) {
        ansInput = (
            <React.Fragment>
                {pref.charAt(props.answNumber)}
                )
                {props.userAnswer ? <CheckBoxOutlinedIcon style={{ verticalAlign: 'middle' }} /> : <CheckBoxOutlineBlankOutlinedIcon style={{ verticalAlign: 'middle' }} />}
            </React.Fragment>
        )
    } else {
        ansInput = '[' + props.userAnswer + ']';
    }

    return (ansInput);
}