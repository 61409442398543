import React, { Component } from 'react';

/*
    таймер, показывающий оставшееся время до конца теста.
*/

export class SummaryTimer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            curTime: this.props.startTime
        }
    }

    componentDidMount() {
        this.intervalId = setInterval(() => this.tik(), 1000)
    }

    tik() {
        let crTime = this.state.curTime - 1000;
        if (crTime <= 0) {
            this.props.onTimeOver();
        } else { 
            this.setState({ curTime: crTime }) 
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render() {
        let spDt = new Date(this.state.curTime);
        return (
            <React.Fragment>Времени осталось: {spDt.getUTCHours() + ':' + spDt.getUTCMinutes() + ':' + spDt.getUTCSeconds()}</React.Fragment>
        );
    }

}