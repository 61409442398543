import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    disBack: {
        left:"0",
        top:"0",
        width: "100%",
        height: "100%",
        position: "fixed",
        background: "#ccc",
        opacity: 0.5,
        zIndex: 1
    },
    indicator: {
        position: "fixed",
        left: "50%",
        top: "50%",
        zIndex: 2
    }
})



const Indicator = () => {
    const classes = useStyles();
    return (<Box className={classes.disBack} ><LinearProgress /></Box>)

}

export function LoadIndicator(props) {
    return props.loading ? <Indicator /> : ''
}