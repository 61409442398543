import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { Button, TextField, Grid, Link, Paper, Typography, Snackbar } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';
import { validateEmail } from './validateTools';


/*
  форма входа
*/

export class LoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      login: this.props.login,
      password: this.props.password,
      loginError: null
    }
  }

  onInputChange(event) {
    if (event.target.id === 'email') {
      this.setState({ loginError: null, passwordError: null, login: event.target.value })
    } else if (event.target.id === 'password') {
      this.setState({ passwordError: null, password: event.target.value })
    }
  }

  onSubmit(event) {
    if (!validateEmail(this.state.login)) {
      this.setState({ loginError: 'введен некорректный адрес эл. почты' })
    } else {
      this.props.onLogin({ login: this.state.login, password: this.state.password });
    }
    event.preventDefault();
  }

  onForgotPassword() {
    if (this.state.login) {
      if (!validateEmail(this.state.login)) {
        this.setState({ loginError: 'введен некорректный адрес эл. почты' })
      } else {
        this.props.onRestorePassword({ email: this.state.login });
      }
    }
  }

  render() {
    let passError = this.state.login === this.props.login && this.state.password === this.props.password ?
      this.props.error :
      null;
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
      >
        <Paper >
          <Box margin={2} maxWidth={400}>
            <Box display='flex' alignItems='center'>
              <Avatar>
                W
              </Avatar>
              <Box ml={2}>
                <Typography variant='h5'>Вход</Typography>
              </Box>
            </Box>

            <Box
              component="form"
              onSubmit={(env) => this.onSubmit(env)}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Эл. почта"
                name="email"
                autoComplete="email"
                autoFocus
                variant="outlined"
                size="small"
                value={this.state.login}
                onChange={(env) => this.onInputChange(env)}
                error={this.state.loginError}
                helperText={this.state.loginError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="outlined"
                size="small"
                value={this.state.password}
                onChange={(env) => this.onInputChange(env)}
                error={passError}
                helperText={passError}
              />
              <Grid container >
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => this.onForgotPassword()}>
                    Забыли пароль?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" onClick={() => this.props.onNeedRegistration()}>
                    {"Впервые здесь? Зарегистрируйтесь"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Войти
                </Button>
              </Box>
            </Box>
          </Box>

        </Paper>
      </Box>
    );
  }

}