import React, { Component } from 'react';
import {QuestionReport} from './QuestionReport'

/*
    секция в режиме отчета
*/

export function SectionReport(props) {
    return (
        props.content.questionsReport.map((itm) => <QuestionReport content={itm}/>)
    )
}