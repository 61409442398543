import { Card, CardContent, CardHeader, Input, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { withStyles, } from '@material-ui/core';

/*
  поле для ввода кода теста
*/

const styles = {
  root: {
    minWidth: 50,
    width: 400,
    height: 200
  },
}

class TestCodeInput extends Component {

  constructor(props) {
    super();
    this.state = {
      testCode: props.initTestCode
    }
  }

  onInputChange(event) {
    const vl = event.target.value;
    const cmpl = /\d{3}-\d{3}-\d{4}/.test(vl);
    this.setState({ testCode: vl });
    if (cmpl) {
      this.props.onSelectCode(vl);
    }
  }


  render() {
    const { classes } = this.props;
        
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
      >
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe">
                W
              </Avatar>
            }
            titleTypographyProps={{ variant: 'h6' }}
            title="Web Test"
          />

          <CardContent>
            <form>
              <InputMask
                mask="999-999-9999"
                maskPlaceholder=""
                value={this.state.testCode}
                onChange={(env) => this.onInputChange(env)}>
                {() =>
                  this.props.error && this.state.testCode === this.props.initTestCode ?
                    <TextField error helperText={this.props.error} variant="outlined" fullWidth="true" size="small" label="Введите код теста" /> :
                    <TextField variant="outlined" fullWidth="true" size="small" label="Введите код теста" />
                }
              </InputMask>
            </form>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default withStyles(styles)(TestCodeInput);
