import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { Button, TextField, Grid, Link, Paper, Typography, Snackbar } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';
import { validateEmail } from './validateTools';

export class RegistrationForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: this.props.email,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            emailError: null
        }
    }

    onInputChange(event) {
        if (event.target.id === 'email') {
            this.setState({ emailError: null, email: event.target.value })
        } else if (event.target.id === 'firstName') {
            this.setState({ firstName: event.target.value })
        } else if (event.target.id === 'lastName') {
            this.setState({ lastName: event.target.value })
        }
    }

    onSubmit(event) {
        if (!validateEmail(this.state.email)) {
            this.setState({ emailError: 'введен некорректный адрес эл. почты' })
        } else {
            this.props.onRegistration({ email: this.state.email, firstName: this.state.firstName, lastName: this.state.lastName });
        }
        event.preventDefault();
    }

    render() {
        let regErr = this.state.emailError ?? this.props.error;
        
        return (<Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            minHeight='100vh'
        >
            <Paper >
                <Box margin={2} maxWidth={400}>
                    <Box display='flex' alignItems='center'>
                        <Avatar>
                            W
                        </Avatar>
                        <Box ml={2}>
                            <Typography variant='h5'>Регистрация</Typography>
                        </Box>
                    </Box>

                    <Box
                        component="form"
                        onSubmit={(env) => this.onSubmit(env)}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Эл. почта"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            variant="outlined"
                            size="small"
                            value={this.state.email}
                            onChange={(env) => this.onInputChange(env)}
                            error={regErr}
                            helperText={regErr}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="firstName"
                            label="Имя"
                            id="firstName"
                            variant="outlined"
                            size="small"
                            value={this.state.firstName}
                            onChange={(env) => this.onInputChange(env)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="lastName"
                            label="Фамилия"
                            id="lastName"
                            variant="outlined"
                            size="small"
                            value={this.state.lastName}
                            onChange={(env) => this.onInputChange(env)}
                        />
                        <Box mt={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Зарегистрировать
                            </Button>
                        </Box>
                    </Box>
                </Box>

            </Paper>
        </Box>);
    }

}