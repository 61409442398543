import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';

/*
    итоги по тесту в отчете
*/

const useStyles = makeStyles((theme) => ({
    repSummary: {
        margin: theme.spacing(1)
    },

    summaryResult: {
        paddingLeft: theme.spacing(1),
        fontWeight: 'bold' 
    }
    
}));

export function ReportSummary(props) {
    const classes = useStyles();

    return(
        <table className={classes.repSummary}>
            <tr>
                <td>Пройдено секций:</td>
                <td className={classes.summaryResult}>{props.content.sectAnswered} из {props.content.sectCount}</td>
            </tr>
            <tr>
                <td>Пройдено вопросов:</td>
                <td className={classes.summaryResult}>{props.content.questAnswered} из {props.content.questCount}</td>
            </tr>
            <tr>
                <td>Набрано баллов:</td>
                <td className={classes.summaryResult}>{props.content.curRating} из {props.content.maxRating}</td>
            </tr>
        </table>
    )
}