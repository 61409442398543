import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ErrorMessage(props) {
    return (
        <Snackbar open={props.message} autoHideDuration={6000} onClose={props.onClose}>
            <Alert onClose={props.onClose} severity="error">
                Произошла ошибка на сервере. Попробуйте еще раз.
            </Alert>
        </Snackbar>
    );
}