import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { AnswerInput } from './AnswerInput';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { withStyles, } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


/*
  вопрос
*/

const styles = {
  contArea: {
    overflow: 'hidden'
  },
}

class Question extends Component {

  constructor(props) {
    super(props);

    const emptyAnsw = this.props.content.qstType == 'single' || this.props.content.qstType == 'check' ? 0 : '';

    this.state = {
      userAnswers: Array(this.props.content.answers.length).fill(emptyAnsw)
    };

  }

  onSetAnswerVal(anwNum, val) {
    let answers = null;
    if (this.props.content.qstType == 'single') {
      answers = Array(this.props.content.answers.length).fill(0);
    } else {
      answers = this.state.userAnswers.slice();
    }

    answers[anwNum] = val;

    this.setState({ userAnswers: answers });
  }

  renderStandartContent() {
    const { classes } = this.props;
    const rawText = this.props.content.qText
      .map((itm) => itm.type === 'text' ? itm.value : '')
      .join(' ');

    const qText = ReactHtmlParser(rawText);

    const answers = this.props.content.answers.map(
      (val, idx) => <div key={idx} className={classes.contArea}>
        <label style={['single', 'check'].includes(this.props.content.qstType) ? { cursor: "pointer" } : {}}>
          <tr>
            <td>
              <AnswerInput
                type={this.props.content.qstType}
                variants={this.props.content.variants}
                curVal={this.state.userAnswers[idx]}
                onSetVal={(nVal) => this.onSetAnswerVal(idx, nVal)} />
            </td>
            <td>
              {ReactHtmlParser(val)}
            </td>
          </tr>
        </label>
      </div>
    );
    return (
      <div>
        <div className={classes.contArea}>{qText}</div>
        <table border="0">{answers}</table>
      </div>

    );
  }

  renderFreeContent() {
    const rawText = this.props.content.qText
      .map((itm) => itm.type === 'text' ? itm.value : '<answinput ></answinput>').
      join('');

    let idx = 0;

    const cont = ReactHtmlParser(rawText, {
      transform: (node) => {
        if (node.type === 'tag' && node.name === 'answinput') {
          const ss = idx++;
          return <AnswerInput
            type={this.props.content.qstType}
            variants={this.props.content.variants}
            curVal={this.state.userAnswers[ss]}
            onSetVal={(nVal) => this.onSetAnswerVal(ss, nVal)}
            key={ss} />;
        } else {
          return undefined;
        }
      }
    });



    return (
      <div>
        {cont}
      </div>
    );
  }

  renderError() {
    if (this.props.error) {
      return (
        <Alert severity="warning">{this.props.error}</Alert>
      )
    }
  }

  render() {
    return (
      <Card>
        {this.renderError()}
        <CardHeader
          titleTypographyProps={{ variant: 'h6' }}
          title={`Вопрос №${this.props.content.totalNumber}`}
        />
        <CardContent>
          {this.props.content.freeFormat ? this.renderFreeContent() : this.renderStandartContent()}
        </CardContent>
        <CardActions>
          <Button size="large" color="primary" onClick={() => this.props.onAnswerCommand(this.state.userAnswers)}>ответить</Button>
          <Button size="large" color="primary" disabled={!this.props.onSkipCommand ? true : false} onClick={() => this.props.onSkipCommand()}>пропустить</Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(Question);
