function fetchJson(url, options, callback) {
    let token = sessionStorage.getItem('token');
    if (token) {
        if (!options) {
            options = {headers:{}}
        }
        options.headers['Authorization'] = 'Bearer ' + token;
    }

    fetch(url, options)
        .then((res) => {
            if (!res.ok) {
                const err = {
                    code: res.status,
                    message: res.statusText,
                    userError: res.status >= 400 && res.status <= 499
                }
                res.json()
                    .then((json) => {
                        err.message = json.message
                        callback(err, null)
                    })
                    .catch((error) => {
                        callback(err, null)
                    })

            } else {
                res.json()
                    .then((json) => {
                        callback(null, json)
                    })
            }
        })
        .catch((error) => {
            const err = {
                code: 500,
                message: error.message,
                userError: false
            }
            callback(err, null);
        })
}

export function getJson(url, callback) {
    fetchJson(url, null, callback);
}

export function postJson(url, postData, callback) {
    fetchJson(
        url,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        },
        callback
    )
}

export function putJson(url, postData, callback) {
    fetchJson(
        url,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        },
        callback
    )
}
