import React, { Component } from 'react';
import { TestStartInfo } from './TestStartInfo';
import { Summary } from './Summary';
import Question from './Question';
import { TestResults } from './TestResults';
import { TestReport } from './TestReport';
import { getJson, postJson } from './comunications';
import { ErrorMessage } from './ErrorMessage';
import { LoadIndicator} from './LoadIndocator';



/* 
  область отображения теста. В зависимости от стадии выполнения теста содержит
   информацию о тесте, вопросы, сообщения, результаты
*/
export class TestArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curTestOut: null,
      reportMode: false,
      userError: null,
      serverError: null
    };

    this.onStartTest = this.onStartTest.bind(this);
    this.onAnswerCommand = this.onAnswerCommand.bind(this);
    this.onSkipCommand = this.onSkipCommand.bind(this);
    this.onShowReport = this.onShowReport.bind(this);
    this.onCloseReport = this.onCloseReport.bind(this);
    this.onTimeOver = this.onTimeOver.bind(this);
  }

  onStartTest() {
    
    this.setState({loading: true});
    
    postJson(`/api/test/${this.props.testInfo.testID}/next`, {}, (err, json) => {
      if (err) {
        this.setState({ serverError: err.message, loading: false });
      } else {
        this.setState({ curTestOut: json, loading: false })
      }
    })
  }

  onAnswerCommand(userAnswers) {
    let st = {
      userError: null,
      serverError: null,
      loading: false
    }
    this.setState({loading: true});
    postJson(`/api/test/${this.props.testInfo.testID}/next`, { 'type': 'answer', 'data': userAnswers }, (err, json) => {
      if (err) {
        if (err.userError) {
          st.userError = err.message;
        } else {
          st.serverError = err.message;
        } 
      } else {
        st.curTestOut = json;
      }
      this.setState(st);
    })
  }

  onSkipCommand() {
    let st = {
      userError: null,
      serverError: null,
      loading: false
    }
    
    this.setState({loading: true});
    
    postJson(`/api/test/${this.props.testInfo.testID}/next`, { 'type': 'skip' }, (err, json) => {
      if (err) {
        if (err.userError) {
          st.userError = err.message;
        } else {
          st.serverError = err.message;
        } 
      } else {
        st.curTestOut = json;
      }
      this.setState(st);
    })
  }

  onShowReport() {
    this.setState({ reportMode: true });
  }

  onCloseReport() {
    this.setState({ reportMode: false });
  }

  onTimeOver() {
    this.onAnswerCommand({});
  }


  renderBody() {
    if (!this.state.curTestOut) {
      return (<TestStartInfo testInfo={this.props.testInfo} onStartTest={this.onStartTest} />);
    }

    if (!this.state.curTestOut.summary.testFinished) {
      return (
        <div>
          <Summary
            content={this.state.curTestOut.summary}
            onTimeOver={this.onTimeOver}
          />
          <Question
            content={this.state.curTestOut.questionOut}
            onAnswerCommand={this.onAnswerCommand}
            onSkipCommand={this.props.testInfo.allowSkip ? this.onSkipCommand : null}
            key={this.state.curTestOut.summary.questAnswered}
            error={this.state.userError}
          />
        </div>
      );
    }

    if (this.state.reportMode) {
      return (
        <TestReport
          content={this.state.curTestOut}
          onCloseReport={this.onCloseReport}
        />
      )
    }

    return (
      <TestResults
        content={this.state.curTestOut.summary}
        onSelectOtherTest={this.props.onSelectOtherTest}
        onRepeatTest={this.props.onRepeatTest}
        onShowReport={this.state.curTestOut.sectionsReport ? this.onShowReport : null}
      />
    );
  }

  render() {
    return(
      <div>
        <LoadIndicator loading={this.state.loading} />
        <ErrorMessage message={this.state.serverError} onClose={() => this.setState({ serverError: null })} />
        {this.renderBody()}
      </div>
    )
  }
}
